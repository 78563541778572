import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { SelectBar } from "../../../containers/";
import { goBack, getIssueEmail, formatDataForCalendar } from "../../../utils/helpers";
import { REALTOR } from "../../../utils/constants"
import NoDataPage from "../../no-data-page/no-data-page";
import LoadingWrapper from "../../../components/loading-wrapper";
import CustomButton from "../../../components/custom-button";
import Header from "../../../components/dashboard-title";
import RealtorDetailCard from "./realtor-detail-card";
import NoteReminderView from "../../common-lead-prospect-details/notes-card";
import RealtorDetailsTable from "./sales-registration-table";
import logo from "../../../images/graphic-no-prospect.png";
import Calendar from "../../../components/calendar/calendar";
import CalendarPopover from "../../common-lead-prospect-details/calendar-popover";
import ActivityButton from "../../../components/activity-button";
import { UpdateRealtorActivityContainer } from "../../common-lead-prospect-forms/activity-container";
import { Tab, Tabs } from "react-bootstrap";
import ContentGrid from "../../../components/grid/content-grid";
import PastActivitiesView from "../../common-lead-prospect-details/past-activities";

/**
 * This component renders the Realtor details page with four main sections:
 * 1. A SelectBar to display the realtor's name.
 * 2. YOY (Year-over-Year) sales and registration tables.
 * 3. A {@link RealtorDetailCard} showing realtor details and allowing edits.
 * 4. A notes and reminders section.
 * 
 * If no realtor is available, a NoDataPage is shown, and a full-page loader appears during data loading. 
 * The component also includes a Calendar for scheduling activities and an activity button for  realtor activities.
 * 
 * @param {Object} props - Contains queries and data for fetching and managing realtor details.
 */

const RealtorDetailsView = props => {
  const { history, getRealtorDetails } = props;
  const [activity, setActivity] = useState(null);
  const [activeTab, setActiveTab] = useState("calendar");
  const [showEditActivity, setShowEditActivity] = useState(false);

  const submitHandler = (refetch, message) => {
    message &&
      props.setNotificationProps({
        variables: { open: true, message: message }
      });
    refetch();
  };

  const toggleShowActivityDrawer = activity => {
    setShowEditActivity(!showEditActivity);
    setActivity(activity);
  };

  const hideEditDrawer = () => {
    setShowEditActivity(false);
    setActivity(null);
  };

  const realtorDetailsView = (id, data, loading, refetch) => {
    const { first_name, last_name } = data;
    const {
      notes,
      sales_count,
      registration_count,
      registrations,
      sales,
      ...details
    } = data;

    return (
      <LoadingWrapper
        loading={loading}
        component={
          <div className="col-padding col-md-12 col-12">
            <div
              className="root-row z4"
              id="prospect-detail-header"
            >
              <SelectBar>
                <div className="d-flex">
                  <CustomButton
                    onClick={() => goBack(history)}
                    id="back-to-dashboard-btn"
                    color="dark"
                    btnIcon={<FaChevronLeft />}
                    className="btn-back"
                  />
                  <Header
                    title="Realtor"
                    subtitle={loading ? "" : first_name + " " + (last_name ? last_name : "")}
                  />
                </div>
              </SelectBar>
            </div>
            <ContentGrid
              content={{
                data: (
                  <div className="row-container main-responsive col-xl-12 row p-2 px-3">
                    <div className="detail-view-main col-padding col-md-8 col-8 mt-2 delete-request-root">
                      <div
                        style={{
                          padding: "1rem",
                          marginTop: "0.5rem",
                          border: "1px solid #191b1d",
                          borderRadius: "0.25rem"
                        }}
                      >
                        <Tabs
                          id="realtor-detail-tabs"
                          activeKey={activeTab}
                          onSelect={(k) => setActiveTab(k)}
                          className="tabs nav nav-tabs"
                        >
                          <Tab
                            eventKey="calendar"
                            title={<div className="d-flex">Calendar</div>}
                            className="tabs nav nav-tabs"
                          >
                            <Calendar
                              id={id}
                              data={!loading ? data : {}}
                              onSubmit={submitHandler.bind(this, refetch)}
                              {...props}
                              popover={CalendarPopover}
                              type={REALTOR}
                              editComp={toggleShowActivityDrawer}
                            />
                            <PastActivitiesView
                              type={REALTOR}
                              pastActivitiesData={
                                !loading ? formatDataForCalendar(getRealtorDetails.getRealtors.realtors[0], "Realtor") : null
                              }
                              id={id}
                              onSubmit={submitHandler.bind(this, refetch)}
                            />
                          </Tab>
                          <Tab
                            eventKey="salesAndRegistrations"
                            title={<div className="d-flex">Sales & Registrations</div>}
                            className="tabs nav nav-tabs"
                          >
                            <div className="row mb-3 mt-3">
                              <div className="col-12">
                                <RealtorDetailsTable data={sales} type="Sales" loading={loading} />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <RealtorDetailsTable data={registrations} type="Registrations" loading={loading} />
                              </div>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                    <div className="col-md-4 col-12 pb-2 pt-2 pl-3 pr-0">
                      <div className="right-column">
                        <RealtorDetailCard data={loading ? null : details} refetch={refetch} />
                        <NoteReminderView
                          {...props}
                          id={id}
                          type={REALTOR}
                          data={loading ? null : data}
                          loading={loading}
                          onSubmit={submitHandler}
                        />
                      </div>
                    </div>
                  </div>
                ),
              }
              }
            />

            {
              showEditActivity && (
                <ActivityButton
                  type={REALTOR}
                  function="UPDATE"
                  item={activity}
                  data={!loading ? data : null}
                  close={hideEditDrawer}
                  {...props}
                  component={UpdateRealtorActivityContainer}
                  onSubmit={submitHandler.bind(this, refetch)}
                />
              )
            }
          </div >
        }
      />
    );
  };
  const { loading, refetch } = getRealtorDetails;
  return !loading && getRealtorDetails.getRealtors.realtors.length === 0 ? (
    <NoDataPage
      {...props}
      backgroundImage={logo}
      heading={"No Realtor Found."}
      content={<div>
        <p className="message-reportBug">The resource for this realtor was not found in our records.<br />If it's a bug, please report.<br /><a href={getIssueEmail()} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Report</a></p>
      </div>}
    />
  ) : (
    realtorDetailsView(
      parseInt(props.match.params.id),
      loading ? {} : getRealtorDetails.getRealtors.realtors[0],
      loading,
      refetch
    )
  );
};

export default RealtorDetailsView;
