import React, { useEffect, useState, useContext } from "react";
import { activityForm, addLeadActivitySchema, addActivitySchema, addRealtorActivitySchema } from "../../utils/activity-form-schema";
import { appointmentTypeDurations, TIME_FORMAT_24, ROLE_OSC, REALTOR } from "../../utils/constants";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCalendarCheck, FaCalendarDay } from "react-icons/fa";
import { LEAD, HOMEOWNER } from "../../utils/constants"
import { Form, Field, withFormik } from "formik";
import { DefaultQuery } from "../../graphql/default-query";
import { FaBan } from "react-icons/fa";
import { Auth } from "../../rbac/rbac";
import TextField from "../../components/fields/text-field";
import SelectField from "../../components/fields/select-field";
import DatePickerView from "../../components/fields/date-picker";
import CheckboxField from "../../components/fields/checkbox-field";
import ErrorFocus from "../../components/error-focus/error-focus";
import CustomButton from "../../components/custom-button/index";
import LoadingWrapper from "../../components/loading-wrapper";
import TextAreaField from "../../components/fields/text-area";
import ScheduleChart from "../../components/schedule-chart/schedule-chart";
import TimeAreaField from "../../components/fields/time-area-field";
import "./common-lead-prospect-forms.css";
import {
  GET_CSM,
  GET_CSM_AVAILABILITY_TIMESLOTS,
  GET_OSC_AVAILABILITY_TIMESLOTS
} from "../../graphql/queries";
import {
  getTimeFromDate,
  isNotNULL,
  getHourDiff,
  formatDate,
  formatTimeHhMm,
  setAppointmentEndTimeFormat,
  filterCommunitiesByDivisions
} from "../../utils/helpers";

/**
 * This form is used to create and update an appointment or a followup. Its a pretty basic form containing multiple text fields,text area,  a select field, and also date and time fields. It has few required fields , without filling them form cannot be submitted. On submission type of the activity and the form
 * is checked and appropriate mutation is called to either create or edit appointment/followup.
 *
 * @param {Object} props
 * @param {Object} props.values object containing activities info to be edited
 * @param {string} props.type type of the customer
 */
const ActivityForm = (props) => {
  const { user } = useContext(Auth);
  const isOsc = user.role === ROLE_OSC || user.secondary_role === ROLE_OSC

  let showAppointmentTab = true
  const { values, type } = props;
  const [timeFilterType, setTimeFilterType] = useState('AM')
  const [scheduleErrors, setScheduleErrors] = useState(null)

  if (!isOsc && type === LEAD) {
    showAppointmentTab = false
    values.appointment = false
  }

  const handleTimeSlotSelection = ({ meetingStartDateTime, meetingEndDateTime }, csm_data) => {
    props.setFieldValue('csm_id', csm_data.id)
    props.setFieldValue("start_datetime", new Date(meetingStartDateTime))
    props.setFieldValue("end_datetime", new Date(meetingEndDateTime))
  }

  const getAvailableSchedulesForOsc = (oscAvailableTimeSlots) => {
    return oscAvailableTimeSlots?.[0].timeSlots.map(slot => {
      return String(formatTimeHhMm(slot.meetingStartDateTime, TIME_FORMAT_24))
    })
  }

  let communities = props.community_id?.length
    ? props.community_id
    : props.getAllCommunities?.getAllCommunities;

  const getDivisionfromCommunity = (communityId) => {
    const divisionId = communityId &&
      communities.filter(community => community.id === communityId)
    return divisionId?.length ? divisionId[0].division_id : null
  }

  useEffect(() => {
    if (props.editForm) {
      const startTime = getTimeFromDate(values.start_datetime)
      const [hour] = startTime.split(':')

      setTimeFilterType(parseInt(hour) > 12 ? 'PM' : 'AM')
    }
  }, [])

  useEffect(() => {
    if (props.isSubmitting && props.errors) {
      setScheduleErrors(props.errors.csm_id ? 'Please select a CSM and Timeslot' : null)
    }
    if (values.csm_id) {
      setScheduleErrors(null)
    }
  }, [props])

  useEffect(() => {
    if (type === LEAD && values.appointment) {
      values.csm_id = user?.id
    }
  }, [values.community_id])

  return (
    <Form className={"form-horizontal"} noValidate>
      <div className="form_TitleContainer">
        <div style={{ margin: "0 1.1rem" }}>
          <div className="form_Title">Calendar</div>
          <div className="form_TitleBig mt-1">
            <div className="d-flex align-items-center">
              {values.inActiveCsm && !values.inActiveCsm.is_active ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip style={{ zIndex: 2022 }}>
                      Inactive CSM: Please select from options for empty CSM
                      field
                    </Tooltip>
                  }
                >
                  <FaBan size={18} className="td-warning" />
                </OverlayTrigger>
              ) : null}
              <h2>
                {type === HOMEOWNER ? "Appointment" : "Appointment/Follow-Up"}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="form_body">
        <div className="form-row">
          <div className="col-md-12">
            <Field
              label="Title"
              name="title"
              id="title"
              type="text"
              required
              component={TextField}
            />
          </div>
        </div>

        <div className="form-row">
          {props.function === "CREATE" &&
            (<div>
              {showAppointmentTab &&
                <CustomButton
                  btnIcon={<FaCalendarCheck />}
                  btnValue="APPOINTMENT"
                  className={values.appointment ? "appointment-button-active" : "appointment-button"}
                  onClick={() => {
                    props.setFieldValue("appointment", true);
                  }}
                />
              }
              <CustomButton
                btnIcon={<FaCalendarDay />}
                btnValue="FOLLOW-UP"
                className={values.appointment ? "appointment-button" : "appointment-button-active"}
                onClick={() => {
                  props.setFieldValue("appointment", false);
                }}
              />
            </div>
            )}
        </div>

        <div className="form-row">
          {(type === LEAD || type === REALTOR) && (
            <>
              {values.appointment && (
                <div className="col-md-7">
                  <Field
                    label="Select a Division"
                    name="division_id"
                    id="division_id"
                    required={values.appointment}
                    fieldsToClear={[{ fieldName: `community_id`, value: null }]}
                    component={SelectField}
                  >
                    {(props.division_id || []).map((division, index) => (
                      <option key={index} value={division.id}>
                        {division.name}
                      </option>
                    ))}
                  </Field>
                </div>
              )}
              <div className="col-md-7">
                <Field
                  label="Assign a Community"
                  name="community_id"
                  id="community_id"
                  required={type === REALTOR || !values.appointment}
                  fieldsToClear={[
                    { fieldName: `csm_id`, value: null },
                    ...(type === REALTOR
                      ? [{ fieldName: `appointment_type_id`, value: null }]
                      : []
                    )
                  ]}
                  component={SelectField}
                >
                  <option value="">Select...</option>
                  {(
                    values.appointment
                      ? filterCommunitiesByDivisions(communities, [values.division_id])
                      : communities
                  )?.map((community, index) => (
                    <option key={index} value={community.id}>
                      {community.name}
                    </option>
                  ))}
                </Field>
              </div>
            </>
          )}
        </div>

        {values.appointment ? (
          <>
            <div className="form-row">
              <div className="col-md-3">
                <Field
                  label="Appointment Type"
                  name="appointment_type_id"
                  id="appointment_type_id"
                  disabled={type === REALTOR && !values.community_id}
                  required
                  isString
                  component={SelectField}
                >
                  <option value="" disabled>Select...</option>
                  {props.getAllAppointmentTypes &&
                    isNotNULL(
                      props.getAllAppointmentTypes
                        .getAllAppointmentTypes
                    )
                    ? props.getAllAppointmentTypes.getAllAppointmentTypes.map(
                      (appointmentType) => (
                        (type !== LEAD || appointmentType.name !== "In-Person") &&
                        <optgroup key={appointmentType.name} label={appointmentType.name}>
                          {appointmentTypeDurations[appointmentType.name].map((duration, index) => (
                            <option key={index} value={`${appointmentType.id}__${duration}`}>
                              {`${duration >= 60 ? `${duration / 60} hour${duration > 60 ? 's' : ''}` : `${duration} minutes`}`}
                            </option>
                          ))}
                        </optgroup>
                      )
                    )
                    : null}
                </Field>
              </div>
              <div className="ml-1" style={{ maxWidth: '110px' }}>
                <Field
                  label="Date"
                  name="appointment_datetime"
                  id="appointment_datetime"
                  required
                  value={values.appointment_datetime}
                  component={DatePickerView}
                />
              </div>

              {type === LEAD && values.appointment_type_id && (
                <div className="ml-1" style={{ maxWidth: '110px' }}>
                  <DefaultQuery
                    query={GET_OSC_AVAILABILITY_TIMESLOTS}
                    variables={{
                      duration: parseInt(values.appointment_type_id.split('__')[1]),
                      appointment_date: formatDate(values.appointment_datetime)
                    }}
                    fetchPolicy="network-only"
                  >
                    {({ data, loading, error }) => {
                      return !error ? (
                        <div>
                          <Field
                            label="Start Time"
                            name="startTime"
                            id="startTime"
                            required
                            disabled={!values.appointment_type_id}
                            customList={loading
                              ? [values.startTime]
                              : getAvailableSchedulesForOsc(data.getOscAvailabilityTimeSlots)
                            }
                            value={values.startTime}
                            component={TimeAreaField}
                          />
                        </div>
                      ) : null
                    }}
                  </DefaultQuery>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="form-row">
              <div className='col-md-6'>
                <DefaultQuery
                  query={GET_CSM}
                  variables={{
                    community_ids:
                      (type === LEAD)
                        ? values.community_id
                          ? [values.community_id]
                          : null
                        : null,
                    ...(type === LEAD
                      ? {
                        division_ids: getDivisionfromCommunity(values.community_id)
                      }
                      : {}),
                  }}
                  fetchPolicy="network-only"
                >
                  {({ data, loading, error }) => {
                    let defaultValue = loading
                      ? "Loading ..."
                      : error
                        ? error.message
                        : "Select...";
                    if (
                      !loading &&
                      data.getAllCSMs?.length === 1 &&
                      values.csm_id === null
                    ) {
                      props.setFieldValue("csm_id", data.getAllCSMs[0].id);
                    }
                    return (
                      <Field
                        name="csm_id"
                        label="Assign a CSM"
                        id="csm_id"
                        required={true}
                        component={SelectField}
                        disabled={
                          (type === LEAD && !values.community_id) ||
                          values.appointment
                        }
                      >
                        <option value="">{defaultValue}</option>
                        {data && isNotNULL(data.getAllCSMs)
                          ? data.getAllCSMs.map((csm, csmIndex) => (
                            <option key={csmIndex} value={csm.id}>
                              {csm.first_name} {csm.last_name}
                            </option>
                          ))
                          : null}
                      </Field>
                    );
                  }}
                </DefaultQuery>
              </div>
              <div className="col-md-2">
                <Field
                  label="Date"
                  name="follow_up_datetime"
                  id="follow_up_datetime"
                  required
                  value={values.follow_up_datetime}
                  minDate={new Date()}
                  component={DatePickerView}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                <Field
                  label="Details"
                  name="details"
                  id="details"
                  required
                  value={values.details}
                  component={TextAreaField}
                  rows={6}
                />
              </div>
            </div>
          </>
        )}

        {type !== LEAD && values.appointment && (
          <div className="form-row">
            <div className='col-md-12'>
              {values.appointment_type_id && (
                <div className="col-md-12">
                  <DefaultQuery
                    query={GET_CSM_AVAILABILITY_TIMESLOTS}
                    variables={{
                      filter: {
                        community_id: type === REALTOR ? values.community_id : props.community_id,
                      },

                      duration: parseInt(values.appointment_type_id.split('__')[1]),
                      appointment_date: formatDate(values.appointment_datetime),
                    }}
                    fetchPolicy="network-only"
                  >
                    {({ data, loading, error }) => {
                      return !error ? (
                        <div className="backdrop-dark">
                          <LoadingWrapper
                            loading={loading}
                            component={
                              <ScheduleChart
                                data={data?.getCsmAvailabilityTimeSlots || []}
                                loading={loading}
                                isEditing={props.editForm}
                                defaultValue={props.editForm && { startTime: values.start_datetime, endTime: values.end_datetime, csm_id: values.csm_id }}
                                value={{ startTime: values.start_datetime, endTime: values.end_datetime, csm_id: values.csm_id }}
                                timeFilter={timeFilterType}
                                setTimeFilterType={setTimeFilterType}
                                handleSelection={handleTimeSlotSelection}
                                errors={scheduleErrors}
                                communityId={type === REALTOR ? values.community_id : props.community_id}
                              />
                            }
                          />
                        </div>
                      ) : null
                    }}
                  </DefaultQuery>
                </div>
              )}
            </div>
          </div>
        )}

        {values.appointment && (
          <div className="form-row">
            <div className="col-md-3">
              <Field
                id="send_email"
                label={`Send Email to ${type === REALTOR ? 'Realtor' : 'Client'}`}
                name="send_email"
                customOnChange={(value) => {
                  props.setFieldValue("send_email", value);
                }}
                component={CheckboxField}
              />
            </div>
            {!(type === REALTOR && props.values.appointment) && (
              <div className="col-md-4">
                <Field
                  id="send_email_to_realtor"
                  label="Send Email to Realtor"
                  name="send_email_to_realtor"
                  customOnChange={(value) => {
                    props.setFieldValue("send_email_to_realtor", value);
                  }}
                  disabled={!props.hasRealtor}
                  component={CheckboxField}
                />
              </div>
            )}
          </div>
        )}
        <div className="form-row">
          <ButtonToolbar>
            <CustomButton
              color="black"
              className={`form_CancelButton`}
              onClick={props.close}
              btnValue="CANCEL"
            />
            <CustomButton
              className={`form_SubmitButton`}
              type="submit"
              disabled={props.isSubmitting}
              btnValue={props.function}
            />
          </ButtonToolbar>
        </div>
        <ErrorFocus />
      </div>
    </Form>
  );
};

function initialiseValues(props) {
  let data = props.item;
  const startTime = String(formatTimeHhMm(data.start_datetime, TIME_FORMAT_24)).split(":");
  const initialValues = Object.assign({}, data);
  initialValues.type = props.type;
  initialValues.appointment = data.__typename === "Appointment" || data.__typename === "RealtorAppointment" ? true : false;
  initialValues.title = data.title;
  initialValues.details = data.details;
  initialValues.appointment_datetime = data.start_datetime
    ? new Date(data.start_datetime)
    : new Date();
  initialValues.start_datetime = data.start_datetime
    ? new Date(data.start_datetime)
    : new Date();
  initialValues.end_datetime = data.end_datetime
    ? new Date(data.start_datetime)
    : new Date();
  initialValues.csm_id = data.csm?.id ?? null;
  initialValues.community_id = data.community?.id ?? null;
  initialValues.division_id = data.division?.id ?? null;
  initialValues.follow_up_datetime = data.follow_up_datetime
    ? new Date(data.follow_up_datetime)
    : new Date();
  initialValues.startTime = `${startTime[0]}:${startTime[1]}`;
  initialValues.appointment_type_id = data.Appointment_Type &&
    `${data.Appointment_Type.id}__${getHourDiff(data.start_datetime, data.end_datetime)}`

  return initialValues;
}

function setInputs(values) {
  const AppointmentInput = {};
  AppointmentInput.title = values?.title;
  AppointmentInput.details = values?.details;
  AppointmentInput.send_email = values?.send_email;
  AppointmentInput.send_email_to_realtor = values?.send_email_to_realtor;
  AppointmentInput.appointment_type_id = values?.appointment_type_id && parseInt(values?.appointment_type_id.split('__')[0]);
  AppointmentInput.csm_id = values?.csm_id;

  const FollowUpInput = {};
  FollowUpInput.follow_up_datetime = values.follow_up_datetime.toUTCString();
  FollowUpInput.title = values.title;
  FollowUpInput.details = values.details;
  FollowUpInput.csm_id = values.csm_id;
  FollowUpInput.community_id = values.community_id ?? null;
  values.type === LEAD || values.type === REALTOR && (FollowUpInput.community_id = values.community_id);

  if (values.type === LEAD && values.appointment) {
    AppointmentInput.division_id = values.division_id;
    AppointmentInput.community_id = values.community_id ?? null;
    AppointmentInput.end_datetime = setAppointmentEndTimeFormat(
      values.startTime,
      values.appointment_datetime,
      parseInt(values.appointment_type_id.split('__')[1])
    )

    const apptTime = new Date(values.appointment_datetime)
    apptTime.setHours(values.startTime.split(':')[0], values.startTime.split(':')[1])
    AppointmentInput.start_datetime = apptTime.toUTCString()
  } else {
    if (values.type === REALTOR && values.appointment) {
      AppointmentInput.division_id = values.division_id;
      AppointmentInput.community_id = values.community_id ?? null;
    }
    AppointmentInput.start_datetime = values.start_datetime.toUTCString();
    AppointmentInput.end_datetime = values.end_datetime.toUTCString();
  }

  return { AppointmentInput, FollowUpInput };
}

export const CreateProspectActivity = withFormik({
  mapPropsToValues: (props) => {
    return { ...activityForm }
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const { AppointmentInput, FollowUpInput } = setInputs(
      Object.assign({}, values)
    );
    AppointmentInput.prospect_id = props.id;
    FollowUpInput.prospect_id = props.id;
    if (values.appointment) {
      props
        .addProspectAppointment({
          variables: {
            input: AppointmentInput,
          },
        })
        .then((res) => {
          if (res.data.addProspectAppointment.code === 200) {
            props.onSubmit(res.data.addProspectAppointment.message);
          }
        });
    } else {
      props
        .addProspectFollowUp({
          variables: {
            input: FollowUpInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.addProspectFollowUp.code === 200) {
            props.onSubmit(res.data.addProspectFollowUp.message);
          }
        });
    }
  },
  validationSchema: addActivitySchema,
  displayName: "new-activity-form",
})(ActivityForm);

export const UpdateProspectActivity = withFormik({
  mapPropsToValues: (props) => {
    return initialiseValues(props);
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const { AppointmentInput, FollowUpInput } = setInputs(values);
    AppointmentInput.appointment_id = props.item.id;
    FollowUpInput.follow_up_id = props.item.id;
    if (values.appointment) {
      props
        .updateProspectAppointment({
          variables: {
            input: AppointmentInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.updateProspectAppointment.code === 200) {
            props.onSubmit(res.data.updateProspectAppointment.message);
          }
        });
    } else {
      props
        .updateProspectFollowUp({
          variables: {
            input: FollowUpInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.updateProspectFollowUp.code === 200) {
            props.onSubmit(res.data.updateProspectFollowUp.message);
          }
        });
    }
  },
  validationSchema: addActivitySchema,
  displayName: "new-activity-form",
})(ActivityForm);

export const CreateLeadActivity = withFormik({
  mapPropsToValues: (props) => ({
    ...activityForm,
    division_id:
      props.division_id?.length ? props.division_id[0].id : null,
    type: LEAD,
    startTime: "00:00"
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    const { AppointmentInput, FollowUpInput } = setInputs(values);
    AppointmentInput.lead_id = props.id;
    FollowUpInput.lead_id = props.id;
    if (values.appointment) {
      props
        .addLeadAppointment({
          variables: {
            input: AppointmentInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.addLeadAppointment.code === 200) {
            props.onSubmit(res.data.addLeadAppointment.message);
          }
        });
    } else {
      props
        .addLeadFollowUp({
          variables: {
            input: FollowUpInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.addLeadFollowUp.code === 200) {
            props.onSubmit(res.data.addLeadFollowUp.message);
          }
        });
    }
  },
  validationSchema: addLeadActivitySchema,
  displayName: "new-activity-form",
})(ActivityForm);

export const UpdateLeadActivity = withFormik({
  mapPropsToValues: (props) => {
    return initialiseValues(props);
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const { AppointmentInput, FollowUpInput } = setInputs(values);
    AppointmentInput.appointment_id = props.item.id;
    FollowUpInput.follow_up_id = props.item.id;
    if (values.appointment) {
      props
        .updateLeadAppointment({
          variables: {
            input: AppointmentInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.updateLeadAppointment.code === 200) {
            props.onSubmit(res.data.updateLeadAppointment.message);
          }
        });
    } else {
      props
        .updateLeadFollowUp({
          variables: {
            input: FollowUpInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.updateLeadFollowUp.code === 200) {
            props.onSubmit(res.data.updateLeadFollowUp.message);
          }
        });
    }
  },
  validationSchema: addLeadActivitySchema,
  displayName: "new-activity-form",
})(ActivityForm);

export const CreateHomeownerActivity = withFormik({
  mapPropsToValues: (props) => ({
    ...activityForm,
    community_id: props.community_id,
    type: HOMEOWNER,
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const { AppointmentInput, FollowUpInput } = setInputs(values);
    AppointmentInput.homeowner_id = props.id;
    FollowUpInput.homeowner_id = props.id;
    if (values.appointment) {
      props
        .addHomeownerAppointment({
          variables: {
            input: AppointmentInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.addHomeownerAppointment.code === 200) {
            props.onSubmit(res.data.addHomeownerAppointment.message);
          }
        });
    } else {
      props
        .addHomeownerFollowUp({
          variables: {
            input: FollowUpInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.addHomeownerFollowUp.code === 200) {
            props.onSubmit(res.data.addHomeownerFollowUp.message);
          }
        });
    }
  },
  validationSchema: addActivitySchema,
  displayName: "new-activity-form",
})(ActivityForm);

export const UpdateHomeownerActivity = withFormik({
  mapPropsToValues: (props) => {
    return initialiseValues(props);
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const { AppointmentInput, FollowUpInput } = setInputs(values);
    AppointmentInput.appointment_id = props.item.id;
    FollowUpInput.follow_up_id = props.item.id;
    if (values.appointment) {
      props
        .updateHomeownerAppointment({
          variables: {
            input: AppointmentInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.updateHomeownerAppointment.code === 200) {
            props.onSubmit(res.data.updateHomeownerAppointment.message);
          }
        });
    } else {
      props
        .updateHomeownerFollowUp({
          variables: {
            input: FollowUpInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.updateHomeownerFollowUp.code === 200) {
            props.onSubmit(res.data.updateHomeownerFollowUp.message);
          }
        });
    }
  },
  validationSchema: addActivitySchema,
  displayName: "new-activity-form",
})(ActivityForm);

export const CreateRealtorActivity = withFormik({
  mapPropsToValues: (props) => ({
    ...activityForm,
    division_id: props.division_id?.length ? props.division_id[0].id : null,
    community_id: props.community_id?.length ? props.community_id[0].id : null,
    type: REALTOR,
    startTime: "00:00",
    appointment: true,
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    const { AppointmentInput, FollowUpInput } = setInputs(values);
    AppointmentInput.realtor_id = props.id;
    FollowUpInput.realtor_id = props.id;
    if (values.appointment) {
      props
        .addRealtorAppointment({
          variables: {
            input: AppointmentInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.addRealtorAppointment.code === 200) {
            props.onSubmit(res.data.addRealtorAppointment.message);
          }
        });
    } else {
      props
        .addRealtorFollowUp({
          variables: {
            input: FollowUpInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.addRealtorFollowUp.code === 200) {
            props.onSubmit(res.data.addRealtorFollowUp.message);
          }
        });
    }
  },
  validationSchema: addRealtorActivitySchema,
  displayName: "new-activity-form",
})(ActivityForm);

export const UpdateRealtorActivity = withFormik({
  mapPropsToValues: (props) => {
    return initialiseValues(props);
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    const { AppointmentInput, FollowUpInput } = setInputs(values);
    AppointmentInput.appointment_id = props.item.id;
    FollowUpInput.follow_up_id = props.item.id;
    if (values.appointment) {
      props
        .updateRealtorAppointment({
          variables: {
            input: AppointmentInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.updateRealtorAppointment.code === 200) {
            props.onSubmit(res.data.updateRealtorAppointment.message);
          }
        });
    } else {
      props
        .updateRealtorFollowUp({
          variables: {
            input: FollowUpInput,
          },
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.updateRealtorFollowUp.code === 200) {
            props.onSubmit(res.data.updateRealtorFollowUp.message);
          }
        });
    }
  },
  validationSchema: addRealtorActivitySchema,
  displayName: "new-activity-form",
})(ActivityForm);