import gql from "graphql-tag";

const GET_REALTOR_LOG = gql`
  query getRealtorLogById($realtor_id: Int) {
    getRealtorLogById(realtor_id: $realtor_id) {
      id
      first_name
      last_name
      primary_email
      secondary_emails
      cell_phone
      Realtor_Logs {
        id
        description
        created_at
        Csm {
          id
          first_name
          last_name
        }
      }
    }
  }
`;
export default GET_REALTOR_LOG;
